import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
    createBrowserRouter,
    RouterProvider,
    createRoutesFromElements,
    Route,
} from "react-router-dom";
import About from "./components/About";
import MyPlaces from "./MyPlaces";
import ErrorPage from "./ErrorPage";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Fragment>
            <Route exact path="/" element={<App />} errorElement={<ErrorPage />}/>
            <Route path="about" element={<About />} />
            <Route path="myplaces" element={<MyPlaces />} />
        </Fragment>
    )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
