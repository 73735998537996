import React from "react";
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";
import places from "./places.json";


function MyPlaces() {
    return (
        <div class="max-w-4xl mx-auto px-4 py-8">
			<Link to="/">
				<ArrowBackIcon fontSize="medium" />
			</Link>

            <div class="bg-white shadow-md rounded-md p-4">
                <h2 class="text-2xl font-semibold text-gray-800 mb-4">
                    Bucketlist
                </h2>

                {places.bucketlist.map((el) => (
                    <div class="flex items-center border-b border-gray-200 py-4">
                        <div class="ml-4 flex-1">
                            <h3 class="text-lg font-semibold text-gray-800">
								{el.title}
                            </h3>
                            <p class="text-gray-600">{el.description}</p>
                        </div>
						<IconButton>
							<ClearIcon fontSize="medium" />
						</IconButton>
                    </div>
                ))}
            </div>

                <h2 class="text-2xl font-semibold text-gray-800 my-4">
                    Visited
                </h2>

                {places.visited.map((el) => (
                    <div class="flex items-center border-b border-gray-200 py-4">
                        <div class="ml-4 flex-1">
                            <h3 class="text-lg font-semibold text-gray-800">
								{el.title}
                            </h3>
                            <p class="text-gray-600">{el.description}</p>
                        </div>
						<IconButton>
							<ClearIcon fontSize="medium" />
						</IconButton>
                    </div>
                ))}

        </div>
    );
}

export default MyPlaces;
