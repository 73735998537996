import React from 'react'
import { Link } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function About() {
  return (
	<div className='my-10 px-5 sm:w-full sm:mx-auto md:w-4/5 w-1/2'>
		<div className='flex flex-row my-10'>
			<Link to="/" className='pr-5'>
				<ArrowBackIcon fontSize='medium' />
			</Link>
			<h1 className='text-2xl'>About BucketMaps</h1>
		</div>
		<p>You have a bucketlist of places you want to visit...</p>
		<br />
		<p>The idea behind BucketMaps is simple: organize your travel destinations on map.</p>

		<br />
		<p>Start using it at the home page (<Link to="/" className='text-red-600'>maps.funnyscar.com</Link>)</p>
		<p>Or visit some other projects <a href="https://board.funnyscar.com" className='text-red-600'>funnyscar.com</a></p>

		<br />
		<p>Made with love from <a href="https://curtisjhu.com" target='_blank' rel="noreferrer" className='text-red-600'>Curtis Hu</a></p>


	</div>
  )
}

export default About