import mapboxgl from "mapbox-gl";
import React, { Fragment, useEffect, useRef, useState } from "react";
// import { AddressAutofill, Geocoder, SearchBox } from "@mapbox/search-js-react";
import Sidebar from "./components/Sidebar";

// MaterialUI
// import Paper from "@mui/material/Paper";
// import InputBase from "@mui/material/InputBase";
// import IconButton from "@mui/material/IconButton";
// import SearchIcon from "@mui/icons-material/Search";

import places from "./places.json";

const visited = places.visited;
const bucketlist = places.bucketlist;

mapboxgl.accessToken = `${process.env.REACT_APP_ACCESS_TOKEN}`;

const Map = () => {
    const mapContainerRef = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-74);
    const [lat, setLat] = useState(40.7);

    // Initialize map when component mounts
    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/streets-v11?optimize=true",
            center: [lng, lat],
            zoom: 9,
        });

        map.current.on("move", () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
        });

        visited.forEach(function (marker) {
            new mapboxgl.Marker()
                .setLngLat(marker.coordinates)
                .setPopup(
                    new mapboxgl.Popup({ offset: 25, closeOnClick: true, closeOnMove: true, closeButton: false, className: "min-w-40" }).setHTML(`
                        <div>
                            <h1 class="text-xl text-black">${marker.title}</h1>
                            <p>${marker.description}</p>
                        </div>
                        `)
                )
                .addTo(map.current);
        });

        bucketlist.forEach(function (marker) {
            new mapboxgl.Marker({ color: "black" })
                .setLngLat(marker.coordinates)
                .setPopup(
                    new mapboxgl.Popup({
                        offset: 25,
                        closeOnMove: true,
                        closeButton: false,
                        closeOnClick: true,
                        className: "min-w-40"
                    }).setHTML(`
                    <div>
                        <h1 class="text-xl text-black">${marker.title}</h1>
                        <p>${marker.description}</p>
                    </div>
                    `)
                )
                .addTo(map.current);
        });
    }, [lat, lng]);

    return (
        <Fragment>
            <div className="absolute top-5 left-5 z-40 backdrop-blur rounded-full text-xl font-bold">Places I've visted</div>

            <div className="absolute bottom-0 left-0 backdrop-blur w-fit p-2 z-40 radius-5">
                Longitude: {lng} | Latitude: {lat}
            </div>

            <div
                className="map-container relative block w-full h-full flex-grow"
                ref={mapContainerRef}
            />

            <Sidebar />
        </Fragment>
    );
};

export default Map;
