import React, { Fragment, useState } from "react";
// import { Dropdown } from "@mui/base/Dropdown";
// import { MenuButton } from "@mui/base/MenuButton";
// import { Menu } from "@mui/base/Menu";
// import { MenuItem } from "@mui/base/MenuItem";
import { useNavigate } from "react-router-dom";

// Drawer
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// ICONS
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import InfoIcon from "@mui/icons-material/Info";
import MapIcon from "@mui/icons-material/Map";
import CottageIcon from "@mui/icons-material/Cottage";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function Sidebar() {
	const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const toggleButton = () => {
        setOpen(!open);
    };

    return (
        <Fragment>
            <IconButton
				className="backdrop-blur rounded-full text-slate-500 hover:text-black z-40 absolute top-5 right-5"
				onClick={() => toggleButton()}
			>
                <MoreVertIcon fontSize="large" />
            </IconButton>

            <Drawer
                className="relative block"
                variant="temporary"
                anchor="right"
                open={open}
            >
                <div className="flex items-center justify-start p-2">
                    <IconButton onClick={() => toggleButton()}>
                        {open ? <ChevronRightIcon /> : <ChevronLeftIcon /> }
                    </IconButton>
                </div>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/myplaces")}>
                            <ListItemIcon>
                                <MapIcon fontSize="medium" />
                            </ListItemIcon>
                            <ListItemText primary="My Places" />
                        </ListItemButton>
                    </ListItem>
                    <Divider />

                    <ListItem disablePadding>
                        <ListItemButton onClick={() => window.open("https://funnyscar.com")}>
                            <ListItemIcon>
                                <CottageIcon fontSize="medium" />
                            </ListItemIcon>
                            <ListItemText primary="funnyscar" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate("/about")}>
                            <ListItemIcon>
                                <InfoIcon fontSize="medium" />
                            </ListItemIcon>
                            <ListItemText primary="About" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemText primary="Log out" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </Fragment>
    );
}

export default Sidebar;