import React from "react";

function ErrorPage() {
    return (
        <div class="bg-gray-100 flex items-center justify-center h-screen">
            <div class="text-center">
                <h1 class="text-6xl font-bold text-gray-800">404</h1>
                <p class="text-2xl text-gray-600 mt-4">Oops! Page not found.</p>
                <a href="/" class="text-blue-600 mt-4 inline-block">
                    Go to Home Page
                </a>
            </div>
        </div>
    );
}

export default ErrorPage;
